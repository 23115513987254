@font-face {
  font-family: 'montserrat'; /* Nombre que deseas asignarle a la fuente */
  src: url('./../assets/fonts/montserrat/Montserrat-Light.otf') format('opentype'); /* Ruta a la fuente */
}

@font-face {
  font-family: 'Neue Montreal'; /* Nombre que deseas asignarle a la fuente */
  src: url('./../assets/fonts/neue-montreal/NeueMontreal-Bold.otf') format('opentype'); /* Ruta a la fuente */
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Track */
::-webkit-scrollbar-track {
border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #d8d8d8;
opacity: 10px;
border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb:hover{
background: #c9c9c9;
opacity: 10px;
border-radius: 10px;
}
